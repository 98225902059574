<template>
  <form class="mx-0 flex flex-wrap" @submit.prevent="createChannel()">
    <div class="mt-4">
      <div class="flex items-center gap-x-4">
        <div style="flex-basis:55%;">
          <h1 class="text-xl text-slate-800 dark:text-slate-100 mb-6 text-justify">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.CONFIGURE_WHATSAPP_LITE.TITLE') }}
          </h1>

          <span class="m-0 p-0 text-sm text-slate-600 dark:text-slate-300 text-justify" style="max-width:90%;"
             v-html="$t('INBOX_MGMT.ADD.WHATSAPP.CONFIGURE_WHATSAPP_LITE.DESCRIPTION')"
          />
        </div>

        <div class="mx-8 w-1 h-14 bg-green-500 dark:bg-slate-300 rounded-md"></div>

        <div class="flex flex-col" style="flex-basis:45%;">
          <div class="flex items-center gap-2 cursor-pointer mb-2">
            <input
              v-model="acceptedWhatsappLitePolice"
              type="checkbox"
              class="m-0 cursor-pointer"
            />
            <a
              :href="whatsappLitePolice"
              target="_blank"
              class="m-0 p-0 text-sm text-slate-600 dark:text-slate-300 no-underline visited:text-slate-600 hover:text-green-500"
            >
              {{ $t('INBOX_MGMT.ADD.WHATSAPP.CONFIGURE_WHATSAPP_LITE.ACCEPT_POLICY') }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
      <label :class="{ error: $v.inboxName.$error }">
        {{ $t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.LABEL') }}
        <input
          v-model.trim="inboxName"
          type="text"
          :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.PLACEHOLDER')"
          @blur="$v.inboxName.$touch"
        />
        <span v-if="$v.inboxName.$error" class="message">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.ERROR') }}
        </span>
      </label>
    </div>

    <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
      <label :class="{ error: $v.phoneNumber.$error }">
        {{ $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.LABEL') }}
        <input
          v-model.trim="phoneNumber"
          type="text"
          :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.PLACEHOLDER')"
          @blur="$v.phoneNumber.$touch"
        />
        <span v-if="$v.phoneNumber.$error" class="message">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.ERROR') }}
        </span>
      </label>
    </div>

    <div class="w-full">
      <woot-submit-button
        :disabled="!acceptedWhatsappLitePolice"
        :loading="uiFlags.isCreating"
        :button-text="$t('INBOX_MGMT.ADD.WHATSAPP.SUBMIT_BUTTON')"
      />
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../index';

import { isPhoneE164OrEmpty } from 'shared/helpers/Validators';

export default {
  mixins: [alertMixin],
  data() {
    return {
      inboxName: '',
      phoneNumber: '',
      acceptedWhatsappLitePolice: false,
      whatsappLitePolice: 'https://docs.google.com/document/d/e/2PACX-1vRJr-LO9m3OjVFdQPIEgtFC9TMUuSlBy8xj-0oqfbLuMzERByMO92myXdwGksm6HH_Vum7zk-V55qUK/pub'
    };
  },
  computed: {
    ...mapGetters({ uiFlags: 'inboxes/getUIFlags' }),
  },
  validations: {
    inboxName: { required },
    phoneNumber: { required, isPhoneE164OrEmpty },
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        const whatsappChannel = await this.$store.dispatch(
          'inboxes/createChannel',
          {
            name: this.inboxName,
            channel: {
              type: 'whatsapp',
              provider: 'whatsapp_lite',
              phone_number: this.phoneNumber,
            },
          }
        );

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: whatsappChannel.id,
          },
        });
      } catch (error) {
        const message = error?.message ?? this.$t('INBOX_MGMT.ADD.WHATSAPP.API.ERROR_MESSAGE')
        this.showAlert(message);
      }
    },
  },
};
</script>
