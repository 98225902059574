var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-col py-2 px-2.5 overflow-auto h-full flex"},[_vm._l((_vm.notifications),function(notificationItem){return _c('woot-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],key:notificationItem.id,attrs:{"size":"expanded","color-scheme":"secondary","variant":"link"},on:{"click":() => _vm.onClickNotification(notificationItem)}},[_c('div',{staticClass:"flex-row items-center p-2.5 leading-[1.4] border-b border-solid border-slate-50 dark:border-slate-700 flex w-full hover:bg-slate-75 dark:hover:bg-slate-900 hover:rounded-md"},[(!notificationItem.read_at)?_c('div',{staticClass:"w-2 h-2 rounded-full bg-woot-500"}):_c('div',{staticClass:"w-2 flex"}),_vm._v(" "),_c('div',{staticClass:"flex-col ml-2.5 overflow-hidden w-full flex justify-between"},[_c('div',{staticClass:"flex justify-between"},[_c('div',{staticClass:"items-center flex"},[_c('span',{staticClass:"font-bold text-slate-800 dark:text-slate-100"},[_vm._v("\n              "+_vm._s(`#${
                  notificationItem.primary_actor
                    ? notificationItem.primary_actor.id
                    : _vm.$t(`NOTIFICATIONS_PAGE.DELETE_TITLE`)
                }`)+"\n            ")]),_vm._v(" "),_c('span',{staticClass:"text-xxs p-0.5 px-1 my-0 mx-2 bg-slate-50 dark:bg-slate-700 rounded-md"},[_vm._v("\n              "+_vm._s(_vm.$t(
                  `NOTIFICATIONS_PAGE.TYPE_LABEL.${notificationItem.notification_type}`
                ))+"\n            ")])]),_vm._v(" "),_c('div',[(_vm.hasAssignee(notificationItem))?_c('thumbnail',{attrs:{"src":notificationItem.primary_actor.meta.assignee.thumbnail,"size":"16px","username":notificationItem.primary_actor.meta.assignee.name}}):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"w-full flex"},[_c('span',{staticClass:"text-slate-700 dark:text-slate-200 font-normal overflow-hidden whitespace-nowrap text-ellipsis"},[_vm._v("\n            "+_vm._s(notificationItem.push_message_title)+"\n          ")])]),_vm._v(" "),_c('span',{staticClass:"mt-1 text-slate-500 dark:text-slate-400 text-xxs font-semibold flex"},[_vm._v("\n          "+_vm._s(_vm.dynamicTime(notificationItem.created_at))+"\n        ")])])])])}),_vm._v(" "),(_vm.showEmptyResult)?_c('empty-state',{attrs:{"title":_vm.$t('NOTIFICATIONS_PAGE.UNREAD_NOTIFICATION.EMPTY_MESSAGE')}}):_vm._e(),_vm._v(" "),(!_vm.isLoading && _vm.inLastPage)?_c('woot-button',{attrs:{"size":"expanded","variant":"clear","color-scheme":"primary","class-names":"mt-3"},on:{"click":_vm.openNotificationPage}},[_vm._v("\n    "+_vm._s(_vm.$t('NOTIFICATIONS_PAGE.UNREAD_NOTIFICATION.ALL_NOTIFICATIONS'))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.isLoading)?_c('div',{staticClass:"items-center justify-center my-12 mx-2 text-sm font-medium flex"},[_c('spinner'),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('NOTIFICATIONS_PAGE.UNREAD_NOTIFICATION.LOADING_UNREAD_MESSAGE')))])],1):_vm._e()],2)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }